import React from 'react'
import { Link } from 'gatsby'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'
import { formatDate } from 'utils'

import Intro from 'elements/Intro'
import Projects from './Projects'

const ResearchContent = ({ data, location }) => {
  const {
    researchIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()

  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Research" imgSrc={src} />
        <div className="mt-14 mb-20">
          <h2 className="text-4xl mb-8 font-bold">Call for papers</h2>
          <div className="max-w-982px text-sm">No current calls for papers</div>
        </div>
        <hr style={{ height: '1.8px' }} className="my-20 bg-vividBlue opacity-50" />
        <div>
          <h2 className="text-4xl mb-12 font-bold">Conferences and workshops</h2>
          {data.length &&
            data.map(
              ({
                node: {
                  id,
                  fields: { slug },
                  frontmatter: { title, date }
                }
              }) => {
                return (
                  <Link
                    to={`${location}/${slug}`}
                    key={`${slug}-${id}`}
                    className="flex bg-white py-5 my-7 shadow-news-card w-full max-w-982px rounded-lg select-none transition-all duration-300 hover:shadow-news-card-strong cursor-pointer border-transparent border-2 hover:border-vividBlue z-1 relative"
                  >
                    <div className="flex flex-col justify-between text-sm bg-white px-5 my-3 rounded-lg">
                      <span className="text-sm text-vividBlue font-bold">{title}</span>
                      <span className="text-sm">{formatDate(date, 'en-us')}</span>
                    </div>
                  </Link>
                )
              }
            )}
        </div>
        <hr style={{ height: '2px' }} className="my-20 bg-vividBlue opacity-50" />
        <Projects />
      </div>
    </section>
  )
}

export default ResearchContent
