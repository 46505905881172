import React from 'react'
import Accordion from '../../../elements/Accordion'

const Projects = () => {
  const title =
    'Project: Humanitarian action of the CroAsia Institute (Zagreb School of Economics and Management), Beijing Foreign Studies University (BFSU) and Capital Normal University (CNU): Donation of masks and gloves'
  const content =
    'In May 2020, after the first wave of COVID-19 befell over Croatia, and despite Zagreb earthquake significantly damaged Zagreb School of Economics and Management (ZSEM), the CroAsia Institute (CAC) used its network to help bring material relief to several Croatian hospitals in Zagreb and Slavonski Brod. This humanitarian action was initiated by the Chinese students of Croatian language (the 2016 generation) at Beijing Foreign Studies University (BFSU), PR China. The leaders of the humanitarian action were prof. Peng Yuchao, prof. Albertina Knežević and prof. Yang Lin from the BFSU and ZSEM’s associate and professor at Beijing’s Capital Normal University (CNU), dr. sc. Zvonimir Stopić. The BFSU, CNU, CAI and ZSEM staff contacted hospitals in Croatia, coordinated with the Croatian Embassy and the Civil Protection Headquarters in order to successfully implement this humanitarian action. After several weeks of coordination, shipment arrived to Croatia in second half of May. Masks and gloves were donated directly to the Dr. Josip Benčević Hospital in Slavonski Brod and the KBC Rebro in Zagreb, at the request of donors from China.'
  return (
    <div>
      <h2 className="text-4xl mb-12 font-bold">Projects</h2>
      <Accordion title={title} content={content} list={[]} />
    </div>
  )
}

export default Projects
