import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

const Accordion = ({ title, content, list }) => {
  const [expand, setExpandState] = useState(false)
  const [contentHeight, setContentHeight] = useState('0px')
  const dropdownContent = useRef(null)

  const toogleAccordion = () => {
    setExpandState(!expand)
    setContentHeight(expand ? '0px' : `${dropdownContent.current.scrollHeight + 20}px`)
  }
  return (
    <div
      onClick={toogleAccordion}
      className="bg-white py-5 my-7 shadow-news-card w-full max-w-982px rounded-lg select-none transition-shadow duration-300 hover:shadow-news-card-strong cursor-pointer relative"
    >
      <div className="flex justify-between md:flex-row relative items-center text-sm bg-white px-5 my-3 rounded-lg">
        <span className="text-sm text-vividBlue font-bold">{title}</span>
        <svg
          className={`transform transition-transform duration-300 ease-linear ${expand ? 'rotate-0' : 'rotate-180'}`}
          width="13"
          height="11"
          viewBox="0 0 13 11"
          fill="none"
        >
          <path
            d="M5.63397 0.499999C6.01887 -0.166668 6.98112 -0.166666 7.36602 0.5L12.1292 8.75C12.5141 9.41667 12.0329 10.25 11.2631 10.25L1.73686 10.25C0.967059 10.25 0.485934 9.41667 0.870835 8.75L5.63397 0.499999Z"
            fill="#017AEB"
          />
        </svg>
      </div>

      <div
        style={expand ? { maxHeight: contentHeight } : { maxHeight: contentHeight }}
        className="overflow-hidden text-sm bg-white w-full px-5 rounded-b-lg flex-col transition-all ease-in-out duration-500 cursor-default"
        ref={dropdownContent}
      >
        <p dangerouslySetInnerHTML={{ __html: content }} />

        {list.length > 0 && (
          <ul className="mt-2">
            {list.map((item, i) => (
              <li key={`${i}-${item}`}>- {item}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  list: PropTypes.array
}

export default Accordion
